import { css } from "styled-components";
import { ELEVATION } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

export const showTab = css`
  display: block;
`;
export const hideTab = css`
  display: none;
`;
export const translateXZero = css`
  transform: translateX(0) !important;
`;
export const asc = css`
  align-self: center;
`;
export const fontBold = css`
  font-weight: 600;
`;
export const alignSelfStart = css`
  align-self: flex-start;
`;
export const lineHeight200 = css`
  line-height: 200%;
`;
export const textUppercase = css`
  text-transform: uppercase;
`;
export const alignSelfEnd = css`
  align-self: flex-end;
`;
export const justifyCenter = css`
  justify-content: center;
`;
export const textCenter = css`
  text-align: center;
`;
export const removePaddBottom = css`
  padding-bottom: 0 !important;
`;
export const removePaddTop = css`
  padding-top: 0 !important;
`;
export const removePadd = css`
  padding: 0 0 !important;
`;
export const mobileContainerWrapper = css`
  ${mq.below.md} {
    padding: 0 32px;
  }
`;
export const flexStart = css`
  display: flex;
  align-items: flex-start;
`;
export const flexCenter = css`
  display: flex;
  align-items: center;
`;
export const btnWidth100 = css`
  padding: 8px;
`;
export const ptb32 = css`
  padding: 32px 0 !important;
`;

export const linkCardHoverStyles = `
  box-shadow: ${ELEVATION.l_2};
  transition: all .3s ease-in-out;
  &:hover {
    box-shadow: ${ELEVATION.l_0};
    text-decoration: none;
  }
`;
export const transition = `
  transition: all .3s ease-in-out;
`;
export const hideBelowLg = css`
  ${mq.below.lg} {
    display: none;
  }
`;
