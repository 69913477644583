import { COLORS } from "../../../globals/designSystem";

const variants = {
  download_grey: {
    borderColor: COLORS.grey_12,
    backgroundColor: COLORS.grey_12,
    color: COLORS.grey_100,
    hoverBackgroundColor: COLORS.grey_20,
    hoverColor: COLORS.grey_100,
    hoverBorderColor: COLORS.grey_20,
  },
  grey_90: {
    borderColor: COLORS.grey_90,
    backgroundColor: COLORS.grey_90,
    color: COLORS.white,
    hoverBackgroundColor: COLORS.grey_80,
    hoverColor: COLORS.white,
    hoverBorderColor: COLORS.grey_80,
  },
  transparent_grey: {
    borderColor: COLORS.grey_70,
    backgroundColor: "transparent",
    color: COLORS.grey_70,
    hoverBackgroundColor: COLORS.grey_80,
    hoverColor: COLORS.grey_0,
    hoverBorderColor: COLORS.grey_80,
  },
  transparent_grey_0: {
    borderColor: COLORS.grey_0,
    backgroundColor: "transparent",
    color: COLORS.grey_0,
    hoverBackgroundColor: COLORS.grey_0,
    hoverColor: COLORS.grey_80,
    hoverBorderColor: COLORS.grey_0,
  },
  lightGray: {
    borderColor: COLORS.grey_20,
    backgroundColor: COLORS.grey_20,
    color: COLORS.grey_90,
    hoverBackgroundColor: COLORS.grey_10,
    hoverColor: COLORS.grey_90,
    hoverBorderColor: COLORS.grey_10,
  },
  green: {
    borderColor: COLORS.green_70,
    backgroundColor: COLORS.green_70,
    color: COLORS.grey_0,
    hoverBackgroundColor: COLORS.green_80,
    hoverColor: COLORS.grey_0,
    hoverBorderColor: COLORS.green_80,
  },
  blue: {
    borderColor: COLORS.sky_70,
    backgroundColor: COLORS.sky_70,
    color: COLORS.grey_0,
    hoverBackgroundColor: COLORS.sky_80,
    hoverColor: COLORS.grey_0,
    hoverBorderColor: COLORS.sky_80,
  },
  transparent_blue: {
    borderColor: COLORS.sky_80,
    backgroundColor: "transparent",
    color: COLORS.sky_80,
    hoverBackgroundColor: COLORS.sky_80,
    hoverColor: COLORS.grey_0,
    hoverBorderColor: COLORS.sky_80,
  },
  white: {
    borderColor: COLORS.white,
    backgroundColor: COLORS.white,
    color: COLORS.blue_100,
    hoverBackgroundColor: COLORS.grey_10,
    hoverColor: COLORS.blue_100,
    hoverBorderColor: COLORS.grey_10,
  },
  transparent: {
    borderColor: COLORS.white,
    backgroundColor: "transparent",
    color: COLORS.white,
    hoverBackgroundColor: "rgba(255, 255, 255, 0.1)",
    hoverColor: COLORS.white,
    hoverBorderColor: COLORS.white,
  },
  grey_100: {
    borderColor: "#23303D",
    backgroundColor: "#23303D",
    color: COLORS.white,
    hoverBackgroundColor: "#000",
    hoverColor: COLORS.white,
    hoverBorderColor: "#000",
  },
  transparent_neutral_82: {
    borderColor: COLORS.neutral_82,
    backgroundColor: "transparent",
    color: COLORS.neutral_82,
    hoverBackgroundColor: COLORS.neutral_82,
    hoverColor: COLORS.neutral_4,
    hoverBorderColor: COLORS.neutral_82,
  },
  neutral_82: {
    borderColor: COLORS.neutral_82,
    backgroundColor: COLORS.neutral_82,
    color: COLORS.neutral_4,
    hoverBackgroundColor: COLORS.neutral_74,
    hoverColor: COLORS.neutral_4,
    hoverBorderColor: COLORS.neutral_74,
  },
  neutral_white: {
    borderColor: COLORS.white,
    backgroundColor: COLORS.white,
    color: COLORS.neutral_1000,
    hoverBackgroundColor: COLORS.neutral_100,
    hoverColor: COLORS.neutral_1000,
    hoverBorderColor: COLORS.neutral_100,
  },
  neutral_dark: {
    borderColor: "#111927",
    backgroundColor: "#111927",
    color: COLORS.white,
    hoverBackgroundColor: "#000615",
    hoverColor: COLORS.white,
    hoverBorderColor: "#000615",
    hoverBoxShadow:
      "0px 4px 8px -2px rgba(28, 38, 63, 0.1), 0px 2px 4px -2px rgba(28, 38, 63, 0.06)",
  },
  neutral_dark_dark_mode: {
    borderColor: "#2C64F4",
    backgroundColor: "#2C64F4",
    color: COLORS.white,
    hoverBackgroundColor: "#638FFF",
    hoverColor: COLORS.white,
    hoverBorderColor: "#638FFF",
    hoverBoxShadow:
      "0px 4px 8px -2px rgba(28, 38, 63, 0.1), 0px 2px 4px -2px rgba(28, 38, 63, 0.06)",
  },
  transparent_white: {
    borderColor: "#6C737F",
    backgroundColor: COLORS.white,
    color: "#111927",
    hoverBackgroundColor: "#F3F4F6",
    hoverColor: "#111927",
    hoverBorderColor: "#6C737F",
    hoverBoxShadow:
      "0px 4px 8px -2px rgba(28, 38, 63, 0.1), 0px 2px 4px -2px rgba(28, 38, 63, 0.06)",
  },
  transparent_white_dark_mode: {
    borderColor: "#6C737F",
    backgroundColor: "#000615",
    color: "#F3F4F6",
    hoverBackgroundColor: "#111927",
    hoverColor: "#F3F4F6",
    hoverBorderColor: "#111927",
    hoverBoxShadow:
      "0px 4px 8px -2px rgba(28, 38, 63, 0.1), 0px 2px 4px -2px rgba(28, 38, 63, 0.06)",
  },
  price_blue: {
    borderColor: "#1E56E3",
    backgroundColor: "transparent",
    color: "#1E56E3",
    hoverBackgroundColor: "#1E56E3",
    hoverColor: "#fff",
    hoverBorderColor: "#1E56E3",
  },
  dark_price: {
    borderColor: "#000615",
    backgroundColor: "#000615",
    color: "#fff",
    hoverBackgroundColor: "#fff",
    hoverColor: "#000615",
    hoverBorderColor: "#000615",
  },
  neutral_400: {
    borderColor: "#9DA4AE",
    backgroundColor: "#FFF",
    color: "#1F2A37",
    hoverBackgroundColor: "#E5E7EB",
    hoverColor: "#1F2A37",
    hoverBorderColor: "#9DA4AE",
  },
  neutral_400_transparent_bg: {
    borderColor: "#9DA4AE",
    backgroundColor: "transparent",
    color: "#1F2A37",
    hoverBackgroundColor: "#fff",
    hoverColor: "#1F2A37",
    hoverBorderColor: "#9DA4AE",
  },
  price_blue_100: {
    borderColor: "#F0F4FF",
    backgroundColor: "#F0F4FF",
    color: "#1E56E3",
    hoverBackgroundColor: "#DFE8FF",
    hoverColor: "#1E56E3",
    hoverBorderColor: "#DFE8FF",
  },
  price_blue_600: {
    borderColor: "#1E56E3",
    backgroundColor: "#1E56E3",
    color: "#FFFFFF",
    hoverBackgroundColor: "#0D41C6",
    hoverColor: "#FFFFFF",
    hoverBorderColor: "#0D41C6",
  },
  blue_500: {
    borderColor: COLORS.blue_500,
    backgroundColor: COLORS.blue_500,
    color: COLORS.white,
    hoverBackgroundColor: COLORS.blue_600,
    hoverColor: COLORS.white,
    hoverBorderColor: COLORS.blue_600,
  },
  white_blue_600: {
    borderColor: COLORS.white,
    backgroundColor: COLORS.white,
    color: COLORS.blue_600,
    hoverBackgroundColor: COLORS.grey_10,
    hoverColor: COLORS.blue_600,
    hoverBorderColor: COLORS.grey_10,
  },
  blue_600_transparent: {
    borderColor: COLORS.blue_600,
    backgroundColor: COLORS.white,
    color: COLORS.blue_600,
    hoverBackgroundColor: COLORS.blue_600,
    hoverColor: COLORS.white,
    hoverBorderColor: COLORS.blue_600,
  },

  blue_600: {
    borderColor: COLORS.blue_600,
    backgroundColor: COLORS.blue_600,
    color: COLORS.white,
    hoverBackgroundColor: COLORS.blue_700,
    hoverColor: COLORS.white,
    hoverBorderColor: COLORS.blue_700,
  },
};

const btnWidths = {
  widthAuto: {
    btnWidth: "auto",
  },
  width100: {
    btnWidth: "100%",
  },
};

const borderRadius = {
  radius8: {
    raduis: "8px",
  },
  radius4: {
    raduis: "4px",
  },
};

const sizes = {
  download: {
    padding: "6px 10px",
    fontSize: "14px",
    height: "38px",
  },
  sm: {
    padding: "4px 16px",
    fontSize: "12px",
    height: "32px",
  },
  md: {
    padding: "13px 30px",
    fontSize: "16px",
    height: "48px",
  },
  smd: {
    padding: "10px 24px",
    fontSize: "16px",
    height: "40px",
  },
  lg: {
    padding: "20px 32px",
    fontSize: "16px",
    height: "54px",
  },
  price_sm: {
    padding: "10px 24px",
    fontSize: "16px",
    height: "44px",
  },
};

const defaults = {
  variant: variants.blue,
  size: sizes.md,
  btnWidth: btnWidths.widthAuto,
  raduis: borderRadius.radius4,
};

export { variants, sizes, btnWidths, borderRadius, defaults };
