import styled from "styled-components";

export const StyledContainerWrapper = styled.section`
  /* max-width: 1176px; */
  max-width: ${props => (props?.isPricingPage ? "1320px" : "1176px")};
  margin: 0 auto;
  clear: both;
  padding: 0 32px;

  @media (min-width: 600px) {
    .p-break {
      display: block;
      margin-top: 16px;
    }
  }
`;

export default StyledContainerWrapper;
