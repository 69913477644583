import styled from "styled-components";
import { sizes, variants, btnWidths, borderRadius, defaults } from "./options";

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "IBM Plex Sans";
  font-style: normal;
  /* font-weight: 400; */
  font-weight: ${props => props?.fontWeight || "400"};
  line-height: 100%;
  letter-spacing: 0.02em;
  line-height: 150%;
  border-radius: ${({ raduis }) => borderRadius[raduis]?.raduis || defaults.raduis.raduis};
  /* text-transform: uppercase; */
  transition: all 0.2s ease-in-out;
  font-size: ${({ size }) => sizes[size]?.fontSize || defaults.size.fontSize};
  height: ${({ size }) => sizes[size]?.height || defaults.size.height};
  width: ${({ btnWidth }) => btnWidths[btnWidth]?.btnWidth || defaults.btnWidth.btnWidth};
  padding: ${({ size }) => sizes[size]?.padding || defaults.size.padding};
  color: ${({ variant }) => variants[variant]?.color || defaults.variant.color};
  border: 1px solid
    ${({ variant }) => variants[variant]?.borderColor || defaults.variant.borderColor};
  background-color: ${({ variant }) =>
    variants[variant]?.backgroundColor || defaults.variant.backgroundColor};
  &:hover {
    color: ${({ variant }) => variants[variant]?.hoverColor || defaults.variant.hoverColor};
    background-color: ${({ variant }) =>
      variants[variant]?.hoverBackgroundColor || defaults.variant.hoverBackgroundColor};
    border: 1px solid
      ${({ variant }) => variants[variant]?.hoverBorderColor || defaults.variant.hoverBorderColor};
    box-shadow: ${({ variant }) => variants[variant]?.hoverBoxShadow || "none"};
    .arrow {
      transform: translateX(5px);
    }
    .rotate90 {
      transform: rotate(90deg);
    }
  }
  .leftBtnIcon {
    margin-right: 8px;
  }
  .rightBtnIcon {
    margin-left: 8px;
  }
  .arrow {
    margin-left: 6px;
    transition: all 0.3s ease-in-out;
  }
  .rotate90 {
    transform: rotate(90deg);
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  .loadingSpinner {
  }
`;

export default StyledButton;
