import React, { useState, useEffect } from "react";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledFaqWrapper from "../shared/StyledFaqWrapper";
import { StyledTitle4, StyledSubTitle1, StyledDesc1 } from "../shared/StyledTypography";
import { textCenter, removePaddBottom } from "../shared/CommonStyled";
import StyledButton from "../shared/StyledButton";

const faqAllState = [
  {
    id: "what-types-of-workloads-and-databases-does-hasura-support",
    question: "What types of workloads and databases does Hasura support?",
    answer: (
      <span>
        Hasura supports the following databases:
        <br />
        <ul className="discUl">
          <li className="disc">
            PostgresQL (and the Postgres family of databases: Yugabyte, Timescale, Citus, Aurora)
          </li>
          <li className="disc">SQL Server</li>
          <li className="disc">Big Query</li>
        </ul>
      </span>
    ),
  },
  {
    id: "how-does-hasura-work",
    question: "How does Hasura work?",
    answer: (
      <span>
        Although Hasura presents itself as a web-service, Hasura is quite a JIT compiler, Hasura
        takes incoming GraphQL API calls over HTTP and then tries to achieve theoretically optimal
        performance while delegating the data fetches to downstream data-sources. You can read more
        about Hasura’s design philosophy in this{" "}
        <a
          href="https://hasura.io/blog/how-hasura-works/"
          target="_blank"
          rel="noopener noreferrer"
        >
          blog post
        </a>
        .
      </span>
    ),
  },
  {
    id: "how-much-time-effort-does-hasura-save",
    question: "How much time & effort does Hasura save?",
    answer: (
      <span>
        Hasura cuts down development time by 50-80%. You can find out more from our case studies{" "}
        <a href="https://hasura.io/case-studies/" target="_blank" rel="noopener noreferrer">
          here
        </a>
        .
      </span>
    ),
  },
  {
    id: "how-do-i-use-hasura-if-i-already-have-an-existing-application-API",
    question: "How do I use Hasura if I already have an existing application or API?",
    answer: (
      <span>
        Hasura is designed for incremental adoption without having to rip-and-replace or entirely
        rebuild your stack. You can incrementally migrate your application to Hasura. Use Hasura to
        first build any new features for your application using your existing data as well as a high
        performance read layer for any read heavy use-cases as this takes no time to set up. You can
        also use any business logic in your existing applications by delegating to them via Hasura
        Actions. This will give you the time to migrate over any legacy code or rewrite existing
        microservices with Hasura.
      </span>
    ),
  },
  {
    id: "where-do-i-put-business-logic",
    question: "Where do I put business logic?",
    answer: (
      <span>
        Hasura exposes your domain’s data and logic over a high-performance flexible API. If you’re
        pointing Hasura at a database you might be wondering, where you’d write the necessary
        business logic that’s required for an API.
        <br />
        <br />
        Note that Hasura removes the need for writing any code required for external or internal
        authorization rules.
        <br />
        <br />
        Hasura provides 4 ways for exposing existing or new business logic in your domain:
        <br />
        <br />
        <span>
          <b>Event triggers:</b>{" "}
        </span>
        Whenever there’s a change in the upstream database, Hasura can capture that change as an
        event and deliver that to a HTTP webhook that can process that data change event and react
        to it asynchronously. Apart from attaching specific pieces of logic to events, this is
        especially useful if you’re thinking about building end to end realtime and reactive
        applications.
        <br />
        <br />
        <ul className="discUl">
          <li className="disc">
            Read more about this architecture at{" "}
            <a href="https://3factor.app/" target="_blank" rel="noopener noreferrer">
              https://3factor.app
            </a>
          </li>
          <li className="disc">
            Read more about event triggers in the Hasura{" "}
            <a href="https://hasura.io/docs/latest/graphql/core/event-triggers/index.html#event-triggers">
              docs
            </a>
            .
          </li>
          <li className="disc">
            Go through a quick tutorial on how to set event triggers up at{" "}
            <a href="https://hasura.io/learn/" target="_blank" rel="noopener noreferrer">
              https://hasura.io/learn/
            </a>
          </li>
        </ul>
        <br />
        <span>
          <b>REST APIs:</b>{" "}
        </span>
        If you have new or existing REST APIs that serve domain data or logic, you can easily
        connect Hasura to them and extend the GraphQL schema that Hasura exposes. This is useful not
        just when you have legacy APIs that contain a lot of transactional or application logic, but
        also when you want to build and serve custom logic with cloud-native code deployed as
        containers or serverless functions.
        <br />
        <br />
        <ul className="discUl">
          <li className="disc">
            Read more about{" "}
            <a href="https://hasura.io/docs/latest/graphql/core/actions/index.html#actions">
              Hasura Actions
            </a>
          </li>
        </ul>
        <br />
        <span>
          <b>GraphQL APIs:</b>{" "}
        </span>
        If you have a new or existing GraphQL service that extends the schema, say with custom
        mutations that incorporate your custom logic, or if you’d like to extend your overall
        GraphQL API with a “sub graph” that comes from a service which you may not directly own, you
        can use “Remote Schemas” in Hasura to bring in GraphQL services as data & logic providers to
        your GraphQL API.
        <br />
        <br />
        <ul className="discUl">
          <li className="disc">
            Read more about{" "}
            <a href="https://hasura.io/docs/latest/graphql/core/remote-schemas/index.html#remote-schemas">
              Remote Schemas
            </a>
          </li>
        </ul>
        <br />
        <span>
          <b>Stored procedures / functions in the database:</b>{" "}
        </span>
        Stored procedures and functions are a common way to write and store high-performance
        business logic, or transactional logic, that is close to the data. As a part of the GraphQL
        API that Hasura exposes over databases, Hasura allows you to expose stored procedures or
        functions as fields in the GraphQL schema. This is a great way to bring in existing business
        logic that maybe in your database, or to write custom, high-performance logic if you’re
        familiar with databases!
        <br />
        <br />
        <ul className="discUl">
          <li className="disc">
            Read more about{" "}
            <a href="https://hasura.io/docs/latest/graphql/core/databases/postgres/schema/custom-functions.html#custom-sql-functions">
              custom functions
            </a>
          </li>
        </ul>
        <br />
        Choose one or more of the methods above depending on where your existing business logic is,
        and where you want it to be in the future.
        <br />
        <br />
        For example, you might have existing logic in synchronous REST APIs in Java or .NET, but you
        might want to write new logic as reactive event triggers deployed as serverless functions
        (or lambdas) in Javascript or Python or Go!
      </span>
    ),
  },
  {
    id: "can-i-use-rest-instead-of-graphql-api",
    question: "Can I use REST instead of GraphQL APIs?",
    answer: (
      <span>
        Hasura 2.0 added support for REST APIs. Hasura 2.0 allows users to create idiomatic REST
        endpoints based on GraphQL templates. Read more{" "}
        <a href="https://hasura.io/docs/latest/graphql/core/api-reference/restified.html#restified-api-reference">
          here
        </a>
        .
      </span>
    ),
  },
  {
    id: "can-hasura-integrate-with-my-authentication-system",
    question: "Can Hasura integrate with my authentication system?",
    answer: (
      <span>
        Hasura believes authentication should not be restricted to a particular provider, hence, we
        make it really easy for you to bring your own authentication system. The most favoured
        mechanism is via JWT. Hasura can accept JWT tokens from any standard JWT provider. For
        extremely customized authentication systems, Hasura also supports auth webhook that allows
        you to read through cookies or tokens that might have a custom format. We have guides for
        some of the popular authentication providers. Read more{" "}
        <a href="https://hasura.io/docs/latest/graphql/core/auth/authentication/index.html#authentication">
          here
        </a>
        .
      </span>
    ),
  },
  {
    id: "does-hasura-also-automatically-cache-queries-data-to-improve-performance",
    question: "Does Hasura also automatically cache queries or data to improve performance?",
    answer: (
      <span>
        Query response caching (available on Hasura Cloud & Hasura EE) can be enabled by specifying
        which query to cache using the @cached directive. Read more about caching{" "}
        <a
          href="https://hasura.io/learn/graphql/hasura-advanced/performance/1-caching/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </span>
    ),
  },
  {
    id: "how-does-hasura-handle-abac-rbac-style-authorization-policies",
    question: "How does Hasura handle ABAC, RBAC style authorization policies?",
    answer: (
      <span>
        Hasura implements RBAC by automatically publishing a different GraphQL schema that
        represents the right queries, fields, and mutations that are available to that role.
        <br />
        <br />
        For ABAC, session variables can be used as attributes and permission rules can be created
        that can use any dynamic variable that is a property of the request.
      </span>
    ),
  },
  {
    id: "does-hasura-have-other-security-features-specifically-for-graphql-in-production",
    question: "Does Hasura have other security features, specifically for GraphQL in production?",
    answer: (
      <span>
        Hasura has multiple security features to best utilize the power of our GraphQL Engine.
        Features like service level security, authentication & authorization, allow lists, rate and
        response limiting are present. Learn more about Hasura security{" "}
        <a
          href="https://hasura.io/learn/graphql/hasura-advanced/security/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </span>
    ),
  },
  {
    id: "how-does-the-compiler-approach-provide-superior-performance",
    question: "How does the compiler approach provide superior performance?",
    answer: (
      <span>
        Typically when you think of GraphQL servers processing a query, you think of the number of
        resolvers involved in fetching the data for the query. This approach can lead to multiple
        hits to the database with obvious constraints associated with it. Batching with data loader
        can improve the situation by reducing the number of calls.
        <br />
        <br />
        Internally Hasura parses a GraphQL query, gets an internal representation of the GraphQL
        AST. This is then converted to a SQL AST and with necessary transformations and variables
        the final SQL is formed.
        <br />
        <br />
        <code>GraphQL Parser -> GraphQL AST -> SQL AST -> SQL</code>
        <br />
        <br />
        This compiler based approach allows Hasura to form a single SQL query for a GraphQL query of
        any depth.
      </span>
    ),
  },
  {
    id: "how-does-hasura-scale-vertically-horizontally",
    question: "How does Hasura scale vertically and horizontally?",
    answer: (
      <span>
        Hasura Cloud lets you scale your applications automatically without having to think about
        the number of instances, cores, memory, thresholds etc. You can keep increasing your number
        of concurrent users and the number of API calls and Hasura Cloud will figure out the
        optimizations auto-magically. Hasura Cloud can load balance queries and subscriptions across
        read replicas while sending all mutations and metadata API calls to the master. Learn more
        about Horizontal scaling with Hasura,{" "}
        <a
          href="https://hasura.io/learn/graphql/hasura-advanced/performance/2-horizontal-scaling/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </span>
    ),
  },
  {
    id: "how-can-i-improve-the-performance-of-slow-running-api-calls",
    question: "How can I improve the performance of slow running API calls?",
    answer: (
      <span>
        Hasura allows analyzing queries to identify the slow running calls and use Indexes to
        improve the performance. Learn more{" "}
        <a
          href="https://hasura.io/learn/graphql/hasura-advanced/performance/3-analyze-query-plans/"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </span>
    ),
  },
];

const WhyHasuraFaq = () => {
  const [openFaqIds, setOpenFaqIds] = useState({});
  const showAnswer = id => {
    const newOpenFaqIds = { ...openFaqIds };
    if (typeof newOpenFaqIds[id] === "undefined") {
      newOpenFaqIds[id] = true;
    } else {
      newOpenFaqIds[id] = !newOpenFaqIds[id];
    }
    setOpenFaqIds(newOpenFaqIds);
  };
  useEffect(() => {
    let removeHash = window.location.hash;
    if (removeHash) {
      removeHash = removeHash.split("#")[1];
      showAnswer(removeHash);
      document
        .getElementById(removeHash)
        .scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
    }
    // eslint-disable-next-line
  }, []);
  const ListFaq = ({ faqItem }) => {
    return (
      <div id={faqItem.id} className="faqListWrapper">
        <StyledDesc1
          role="button"
          tabIndex="0"
          onClick={() => showAnswer(faqItem.id)}
          variant="sky_80"
          className="faqQuestion"
        >
          <span>{faqItem.question}</span>
          <svg
            className={openFaqIds[faqItem.id] ? "rotateImg" : ""}
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.8187 13.9348L17.9987 19.7548L12.1787 13.9348C11.5937 13.3498 10.6487 13.3498 10.0637 13.9348C9.47875 14.5198 9.47875 15.4648 10.0637 16.0498L16.9488 22.9348C17.5338 23.5198 18.4788 23.5198 19.0638 22.9348L25.9487 16.0498C26.5337 15.4648 26.5337 14.5198 25.9487 13.9348C25.3637 13.3648 24.4037 13.3498 23.8187 13.9348Z"
              fill="#0079BD"
            />
          </svg>
        </StyledDesc1>
        <div className={"faqAnswer" + (openFaqIds[faqItem.id] ? " showAnswer" : " hideAnswer")}>
          <StyledDesc1>{faqItem.answer}</StyledDesc1>
        </div>
      </div>
    );
  };
  const faqAllDetails = faqAllState.map((faqItem, index) => {
    return <ListFaq key={index} faqItem={faqItem} />;
  });

  return (
    <StyledSectionWrapper id="faq" css={removePaddBottom}>
      <StyledContainerWrapper>
        <StyledFaqWrapper>
          <StyledTitle4 className="mpb-24" paddingBottom="pb56" variant="grey_100" css={textCenter}>
            Frequently Asked Questions
          </StyledTitle4>
          {faqAllDetails}
          <StyledDesc1 className="pt24" css={textCenter}>
            If you have further questions, ask us on{" "}
            <a
              href="https://github.com/hasura/graphql-engine/discussions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </StyledDesc1>
          <div className="buttonWrapper">
            <StyledSubTitle1 css={textCenter} paddingBottom="pb32">
              Try Hasura for free
            </StyledSubTitle1>
            <a href="https://cloud.hasura.io/signup?pg=why-hasura&plcmt=body-banner&cta=get-started-in-30-seconds&tech=default">
              <StyledButton>Get Started in 30 seconds</StyledButton>
            </a>
          </div>
        </StyledFaqWrapper>
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default WhyHasuraFaq;
