import styled from "styled-components";

import { COLORS } from "../../globals/designSystem";
import { ELEVATION } from "../../globals/designSystem";
import { transition } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";
export const StyledFaqWrapper = styled.div`
  .wd40 {
    width: 40%;
    margin: 0 auto;
  }
  .pt24 {
    padding-top: 24px;
  }
  .faqListWrapper {
    border-radius: 8px;
    overflow: hidden;
    max-width: 800px;
    margin: 16px Auto;
    background-color: ${COLORS.white};
    box-shadow: ${ELEVATION.l_0};
    &:last-child {
      margin-bottom: 80px;
    }
    ul {
      li {
        img {
          width: 16px;
          min-width: 16px;
          margin-top: 6px;
        }
      }
    }
    .faqQuestion, .faqAnswer {
      padding: 12px 24px;
      background-color: ${COLORS.white};
    }
    .faqQuestion {
      display: flex;
      align-items: center;
      &:hover {
        background-color: #F8FCFF;
      }
      span {
        flex: 1;
      }
      svg {
        ${transition}
        transform: rotate(0deg);
      }
      .rotateImg {
        transform: rotate(180deg);
      }
    }
    .showAnswer {
      display: block;
    }
    .hideAnswer {
      display: none;
    }
  }
  .hasConJob {
    margin: 16px 0;
    &:first-child {
      margin-top: 32px;
    }
    &:last-child {
      margin-bottom: 32px;
    }
    .faqQuestion {
      font-weight: 600;
      color: #202020;
      padding: 24px 32px;
      &:hover {
        background-color: #F3F5F7;
      }
    }
    .faqAnswer {
      padding: 32px;
      ul {
        li {
          display: flex;
          align-items: flex-start;
          svg {
            margin-top: 4px;
            margin-right:12px;
          }
          .descItem {
            li {
              list-style-type: circle;
              display: list-item;
            }
          }
        }
      }
      .readMore {
        display: flex;
        align-items: center;
        &:hover {
          svg {
            transform: translateX(5px);
          }
        }
        svg {
          transition: all .3s ease-in-out;
          margin-left: 6px;
        }
      }
    }
    .activeQuestion {
      background-color: #F3F5F7;
    }
  }
  .eventsFaq {
    box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .faqListWrapperLearn {
    margin: 16px 0;
    max-width: 688px;
    &:last-child {
      margin-bottom: 16px;
    }
    .faqQuestion, .faqAnswer {
      padding: 12px 24px;
    }
  }
  .buttonWrapper {
    padding-top: 66px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    a {
      display: flex;
      justify-content: center;
    }
  }
  ${mq.below.md} {
    .mpb-24 {
      padding-bottom: 24px;
    }
    .wd40 {
      width: 100%;
      margin: 0 auto;
    }
    .faqListWrapper {
      .faqQuestion, .faqAnswer {
        padding: 16px 24px;
      }
    }
  }
  ${mq.above.xxxl} {
    .faqWrapperLearn {
      display: grid;
      grid-template-columns: 1fr 424px;
      grid-gap: 32px
    }
  }
`;

export default StyledFaqWrapper;
