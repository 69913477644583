import styled from "styled-components";

import { mq } from "../../globals/utils";

export const StyledSectionWrapper = styled.section`
  /* padding: 100px 0; */
  clear: both;

  padding: ${props => props?.padding || "100px 0"};

  ${mq.between("md", "lg")} {
    padding: 70px 0;
  }
  ${mq.below.md} {
    /* padding: 50px 0; */

    padding: ${props => props?.mobilePadding || "50px 0"};
  }
`;

export default StyledSectionWrapper;
